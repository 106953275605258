<template>
  <div v-if="isLoaded" id="PrivacyPolicy">
    <Hero>
      <h1>{{ privacyPolicy.title }}</h1>
      <HeroScrollDown hash="#policy" slot="outside" />
    </Hero>
    <section id="policy">
      <Wrap>
        <Markdown :markdown="privacyPolicy.content" />
      </Wrap>
    </section>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import Meta from 'views/mixins/Meta'

const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  mixins: [Meta()],
  components: { Hero, HeroScrollDown, Markdown },
  computed: {
    privacyPolicy() {
      return this.query('page', {
        locale: this.locale,
        type: 'PRIVACY_POLICY',
      }, true)
    },
    ...getters(),
  },
  methods: actions('FETCH_PAGE'),
  created() {
    this.LOAD(this.FETCH_PAGE())
  },
}
</script>

<style lang="scss">
#PrivacyPolicy {
  .Hero {
    background-image: url('../../../assets/ellipses.png');
    background-position: right;
    background-size: cover;

    h1 {
      text-align: center;
    }
  }

  #policy {
    padding: 4rem 0;

    h2 {
      font-size: $h3;
      margin: 3rem 0;
    }

    h3 {
      font-size: $h35;
    }

    h3,
    h4,
    ul {
      margin: 2rem 0;
    }

    ul {
      margin-left: 1rem;
    }

    p {
      margin-bottom: 1rem;
    }

    table {
      border: 1px solid $border-color;
      margin: 2rem 0;

      td {
        padding: 1rem;

        &:first-of-type {
          width: 14%;
        }

        &:nth-of-type(3) {
          width: 45%;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "meta": {
      "title": "Datenschutz"
    }
  },
  "en": {
    "meta": {
      "title": "Privacy Policy"
    }
  },
  "it": {
    "meta": {
      "title": ""
    }
  },
  "lt": {
    "meta": {
      "title": "Privatumo politika"
    }
  },
  "pl": {
    "meta": {
      "title": "Polityka prywatności"
    }
  }
}
</i18n>
